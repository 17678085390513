import React, { useReducer, useEffect, createContext } from 'react';
let reducer = (data, newData) => {
  return { ...data, ...newData };
};

const dataQuoation = {};
const localState = JSON.parse(localStorage.getItem('dataContent'));
export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useReducer(reducer, localState || dataQuoation);
  useEffect(() => {
    localStorage.setItem('dataContent', JSON.stringify(data));
  }, [data]);
  return (
    <DataContext.Provider
      value={{
        data,
        setData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
