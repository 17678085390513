import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';

export const FiltersPlans = ({ title, arrayFilters, onFilter }) => {
  const { t } = useTranslation();
  const [listFilter, setListFilter] = useState([]);

  useEffect(() => {
    if (arrayFilters.length <= 0) {
      setListFilter([]);
    }
  }, [arrayFilters]);

  const onCheckFilter = (value) => {
    const name =
      title === 'metalLevels'
        ? 'metal_levels'
        : title === 'issuers'
        ? 'issuers'
        : 'types';
    const alreadyCheked = listFilter.filter((filter) => filter === value);
    if (alreadyCheked.length > 0) {
      const unCheck = listFilter.filter((filter) => filter !== value);
      setListFilter(unCheck);
      onFilter(name, unCheck);
    } else {
      setListFilter((prevstate) => [...prevstate, value]);
      onFilter(name, [...listFilter, value]);
    }
  };

  const rename = (name) => {
    return name === 'metalLevels'
      ? `${t('metal_levels')}`
      : name === 'issuers'
      ? `${t('issuers')}`
      : `${t('networks')}`;
  };
  const isChecked = (value) => {
    const isFiltered = listFilter.find((filter) => filter === value);
    return isFiltered ? true : false;
  };
  return (
    <div className='mt-5'>
      <div className='row d-flex justify-content-center pt-1  '>
        <div className='col'>
          <h3 className='title-cats'>{rename(title)}</h3>
        </div>
      </div>
      <div className='row mt-1 px-3 scroll-cats'>
        {arrayFilters.map((filter) => (
          <div className='form-check d-flex' key={filter.name}>
            <input
              key={filter.name + filter.id}
              id={filter.id}
              className='form-check-input'
              type='checkbox'
              onClick={(e) => onCheckFilter(filter.value)}
              checked={isChecked(filter.value)}
            />
            <label className='form-check-label mx-1'>{filter.value}</label>
          </div>
        ))}
      </div>
    </div>
  );
};
