import React, { useState } from 'react';
import { PaginationPlans } from '../Shared/PaginationPlans';
import { Dropdown } from 'primereact/dropdown';
import { FiltersPlans } from '../Shared/FiltersPlans';
import { PlanCard } from '../Shared/PlanCard';
import { useTranslation } from 'react-i18next';
import './styles/Quoter.css';
import '../../i18n';
import { Container, Row } from 'react-bootstrap';

export const Plans = ({
  plans = [],
  filters = [],
  getPlans,
  members = [],
  currentPage = 1,
  numberPages = 0,
  errorPlan,
}) => {
  const { t } = useTranslation();
{/** */}
  const [order, setOrder] = useState({});
  const [filter, setFilter] = useState({});
  const ordersList = [
    { label: `${t('order_by')}`, order: 'asc', sort: undefined },
    { label: `${t('lower_premium')}`, order: 'asc', sort: 'premium' },
    { label: `${t('lowest_deductible')}`, order: 'asc', sort: 'deductible' },
    { label: `${t("oops")}`, order: 'asc', sort: 'oops' },
   {/** { label: `${t('highest_deductible')}`, order: 'desc', sort: 'deductible' },
      {/* { label: `${t('highest_premium')}`, order: 'desc', sort: 'premium' },*/}
  ];
  const nextPage = (pageNumber) => {
    getPlans(members, 10, pageNumber, order.order, order.sort, filter);
  };
  const onOrder = (e) => {
    setOrder(e.value);
    getPlans(members, 10, 1, e.value.order, e.value.sort, filter);
  };
  const onFilter = (filterName, listFilter) => {
    let filtering = filter;
    filtering[filterName] = listFilter;
    setFilter(filtering);
    getPlans(members, 10, 0, order.order, order.sort, filter);
  };
  return (
    <Container>
    <Row>
    <div className='flex-containercard'>
        <div className='col-10'>
          <h3>{t('plans_information')}</h3>
        </div>
        <div className='col-4'>
          <Dropdown
            value={order}
            options={ordersList}
            optionLabel='label'
            onChange={onOrder}
            placeholder={t('order_by')}
          />
        </div>
      </div>
      {plans && (
        <div className='flex-containercard '>
          <div className='col-3 hide-mobile col-separator'>
            {filters.map((filter, i) => {
              if (
                filter.name === 'metalLevels' ||
                filter.name === 'issuers' ||
                filter.name === 'types'
              ) {
                return (
                  <FiltersPlans
                    key={filter.name + i}
                    arrayFilters={filter.facets}
                    title={filter.name}
                    onFilter={onFilter}
                  />
                );
              }
              return null;
            })}
          </div>
          <div className='col-8'>
            <div className='row mt-3'>
              <PaginationPlans
                pages={numberPages}
                nextPage={nextPage}
                currentPage={currentPage}
              />
            </div>
            {errorPlan && (
              <div className='card card-red-light my-3'>
                <div className='row py-3'>
                  <div className='col-12'>
                    <p className='my-auto mx-2'>{t('invalid_zipCode')}</p>
                  </div>
                  <div className='col-12'>
                    <a className='my-auto mx-2' href={t('url_cuidado_salud')}>
                      {t('url_cuidado_salud')}
                    </a>
                  </div>
                </div>
              </div>
            )}
            {plans.length <= 0 && <p>{t('no_registers')}...</p>}
            {plans.map((plan) => (
              <PlanCard key={plan.id} plan={plan} />
            ))}
            <div className='row mt-3 mb-5'>
              <PaginationPlans
                pages={numberPages}
                nextPage={nextPage}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      )}
    
    </Row>

     
    </Container>
  );
};
