export const translationSP = {
    error_server: 'No ha sido posible conectar con el servidor',
    language: "Idioma",
    welcome:"Halle un plan que se adapte a sus necesidades.",
    enter_data:"Introduzca sus datos para ver diferentes planes.",
    zipcode:"Código postal",
    zipcode_error:"Código postal inválido. Por favor, ingrese uno de Estados Unidos.",
    zipcode_required:"El código postal es obligatorio",
    zipcode_validation:"Debe tener 5 dígitos.",
    name:"Nombre (opcional)",
    last_name:"Apellido",
    email:"Correo electrónico (opcional)",
    email_validation:"El formato del email es incorrecto.",
    phone:"Número de teléfono (opcional)",
    phone_validation:"Debe tener 10 dígitos.",
    plans:"Ver planes y precios",
    disclaimer:"Descargo de responsabilidad: al enviar su información, acepta que Aseguranza Nacional pueda comunicarse con usted a la dirección de correo electrónico o al número de teléfono anteriores entre las 9 a. m. y las 7 p. m. (CST). Proporcionar este consentimiento no es una condición de compra. Puede cancelar la comunicación con Aseguranza Nacional en cualquier momento.",
   /* Segunda parte del cotizador */ 
   /**Componte Quoterpage */
   greeting:"Hola",
   data:"Por favor complete la siguiente información para que podamos determinar las mejores opciones para usted.",
   /**Componente eligibility */
   eligibility:"¿Quién está aplicando?",
   not_eligibility:" Algunos de sus solicitantes no son elegibles para los planes del Mercado.",
   elegible:"Elegible",
   not_elegible:"Aplicando",
   not_elegible_applicant:"Es probable que sus solicitantes no sean elegibles para los ahorros en los planes del Mercado.",
   chip_denied:"CHIP denegado o está terminando",
   chip_not_elegible:"Aplicando",
   savings:"Ahorros",
   by_month:"/Por mes",
   medicaid_elegible:" Puede ser elegible para Medicaid del estado actual.",
   chip_njfc:"Puede ser elegible para CHIP ",
   /**Componente Informacion */
   county:"Condado",
   county_select:"Seleccione condado",
   applygin_coverage:"¿Quién está solicitando cobertura?",
   county_required:"El condado es obligatorio",
   me_age:"Su edad",
   age:"Edad",
   member_age:"Edad",
   me:"Yo",
   male:"Hombre",
   female:"Mujer",
   man: "Hombre",
   woman: "Mujer",
   spouse:"Cónyuge",
   dependent:"Dependiente",
   uses_tobacco:"Consumidor de tabaco",
   pregnant:"Embarazada",
   gender:"Sexo",
   spouse_applicant:" Añadir cónyugue",
   dependent_applicant:"Añadir dependiente",
   family_members:"Miembros del núcleo familiar",
   many:"¿Cuantos miembros?",
   household_income:"Ingresos del núcleo familiar",
   delete:"Borrar",
   close:"Ocultar",
   see_plans:"Ver planes y precios",
   card_found:"¡Hemos encontrado los mejores planes para ti!",
   /* Componente Plans */
   plans_information:"Planes: ",
   invalid_zipCode: "Aunque usted puede inscribirse en una cobertura de salud asequible y de calidad sin importar su lugar de residencia, algunos estados tienen su propio sitio web para solicitar e inscribirse en la cobertura. En este enlace puede consultar qué página web está disponible en su estado: ",
   url_cuidado_salud: "https://www.cuidadodesalud.gov/es/marketplace-in-your-state/",
   no_registers: "No hay registros",
   /**Plan card */
   premium:"Prima mensual estimada",
   was:"era $",
   deductible:"Deducible",
   individual_total:"Total individual",
   moops:"Gastos máximos de su bolsillo",
   oops:"Costos de bolsillo más bajos",
   copays_coinsunrance:"Copagos / Coseguro",
   emergency_care:"Cuidados de emergencia",
   generic_drugs:"Medicamentos genéricos",
   primary_care_physician:"Médico de cuidados primarios",
   medical_specialist:"Médico especialista",
   referral_required:"Referido requerido:",
   summary_of_benefits:"Resumen de beneficios",
   email_client:"Enviar a Email",
   contact_advisor:"Contactar asesor",
   name_contact_advisor:"Nombre (requerido)",
   phone_contact_advisor:"Teléfono (requerido)",

   /**Filters plan */

      /**Filters plan */
      order_by:"Ordenar por",
      lower_premium:"Prima más baja",
      highest_premium:"Prima mas alta",
      lowest_deductible:"Deducible más bajo",
      highest_deductible:"Deducible más alto",
      metal_levels:"Nivel de metal",
      networks:"Redes",
      issuers:"Aseguradora",
      send_mail:"SE HA ENVIADO TU CORREO",


     /**top bar*/

     hours:"Horario: Lun. – Vie. de 10 a.m. a 6 p.m. (CST)"


     /**footer */
     

   };

