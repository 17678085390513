import React, { useContext, useState } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import { Col, Container, Row } from 'react-bootstrap';
import '../Quoter/styles/lenguaje.css'
import { DataContext } from '../../context/DataContext';
import logo from '../../assets/images/YIT-rojo.png'
import { Navigate } from 'react-router-dom';

export const NavBar = () => {
  const { t, i18n } = useTranslation();
  const { data: { zipCode,
    name,
    email,
    tel,
    counties,
    place,
    people,
    members,
    income },
    setData,
  } = useContext(DataContext);
  const [selectLanguage, setselectLanguage] = useState('en');
  const options = ['en', 'es'];
  const changeLanguageHandler = (lang) => {
    setselectLanguage(lang);
    setData({
      zipCode,
      name,
      email,
      tel,
      counties,
      place,
      people,
      members,
      income,
      language: lang
    });
    i18n.changeLanguage(lang);
  };
  return (
    <Container className='header-lan' fluid>
    <Row className='top-bar align-items-right'>
      <Col></Col>
      <Col ><p className='top-bar-text'><a href='tel:8008879414'>tel: (800) 887 9414</a></p></Col>
      <Col ><p className='top-bar-text'><a href='mailto:contact@yestoinsurance.com'>contact@yestoinsurance.com</a></p></Col>
      <Col  xs={4}><p className='top-bar-text'>{t('hours')}</p></Col>
    </Row>
      <Row className='align-items-center  header-bar'>
      <Col >
          <a href='/'><img src={logo} className="logo-yti"></img></a>
      </Col>
        <Col className='lenguaje-selector ' >
          {/* <p className='fw-bold m-0 texto-lenguaje'>{t('language')}:</p> */}
          <SelectButton
            value={selectLanguage}
            options={options}
            onChange={(e) => {
              changeLanguageHandler(e.value);
            }}
          />
        </Col>
      </Row>
    </Container>



  );
};
