import React, { useContext, useEffect, useRef, useState } from 'react';
import { DataContext } from '../../context/DataContext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import * as API from '../../services/quoter';
import { useTranslation } from 'react-i18next';
import '../../i18n';

import './styles/Quoter.css';
import { Container, Row, Col } from 'react-bootstrap';
export const Information = ({ getEligibility }) => {
  const { t } = useTranslation();

  const {
    data: {
      zipCode,
      name,
      email,
      tel,
      counties,
      place,
      people,
      members,
      income,
      language,
      
    },
    setData,
  } = useContext(DataContext);
  const [invalidZipCode, setInvalidZipCode] = useState(false);
    const [error, setError] = useState(false);
  const [invalidAge, setInvalidAge] = useState([]);
  const [isInvalidAge, setIsInvalidAge] = useState(true);
  const [isDependent, setIsDependant] = useState(false)  
  const [familyCounter, setFamilyCounter] = useState(1)
  const MEMBER = {
    applicant: 2,
    age: null,
    gender: 'Male',
    uses_tobacco: false,
    pregnant: false,
    opened: true,
    aptc_eligible: true,  
    has_mec: true, 
   real_age:null
    
  };
  const options = ['M', 'F'];
  useEffect(() => {
    people.forEach((member, i) => checkAge(member, i));
  }, []);

  useEffect(() => {
    onCounties();
  }, [counties]);
  const onCounties = () => {
    if (counties.length === 1) {
      setData({
        zipCode,
        name,
        email,
        tel,
        counties,
        place: counties[0],
        people,
        members,
        income,
        language
      });
    }
  };
  const checkZIP = () => {
    if (zipCode === '' || zipCode.length < 5) {
      setInvalidZipCode(true);
      return 0;
    }
    API.getCountriesByZip(zipCode)
      .then(({ counties }) => {
        if (counties.length <= 0) {
          setInvalidZipCode(true);
          setData({
            zipCode,
            name,
            email,
            tel,
            counties: [],
            place: {},
            people,
            members,
            income,
            language
          });
        } else {
          setInvalidZipCode(false);
          setData({
            zipCode,
            name,
            email,
            tel,
            counties,
            place: {},
            people,
            members,
            income,
            language
          });
        }
      })
      .catch((err) => {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 6000);
      });
  };
  const found = people.find((obj) => {
    return obj.applicant === 1;
  });
  const getMembersList = () => {
    let numberArray = [];
    for (var i = 1; i <= 20; i++) {
      numberArray.push(i);
    }
    return numberArray;
  };
  const membersList = getMembersList();

  const onChangeFamilyInfo = (key, i, value) => {
    people[i][key] = value;
    if (key === 'gender' && value === 'M') people[i].pregnant = false;
    setData({
      zipCode,
      name,
      email,
      tel,
      counties,
      place,
      people: [...people],
      members,
      income,
      language
    });
  };

  const onCountyChange = (e) => {
    setData({
      zipCode,
      name,
      email,
      tel,
      counties,
      place: e.value,
      people,
      members,
      income,
      language
    });
  };
  const onDeleteMember = (i) => {
    people.splice(i, 1);
    setData({
      zipCode,
      name,
      email,
      tel,
      counties,
      place,
      people,
      members,
      income,
      language
    });

  };
  const titleCard = (applicant) => {
    if (applicant === 0) return `${t('me')}`;
    else if (applicant === 1) return `${t('spouse')}`;
    else return `${t('dependent')}`;
  };
  const checkAge = (member, i) => {
    if (people.length === 1 && income === 0 && !member.real_age) {
      setIsInvalidAge(true);
      return 0;
    }
    const listAges = invalidAge;
    listAges[i] = member.age > 0 ? true : false;
    setInvalidAge(listAges);
    const invalid = listAges.find((age) => age === false);
    setIsInvalidAge(invalid === false ? true : false);
    
  };

  return (
     <Container fluid>
       <Row className='row information-container'>
      {error && (
        <Col className='error-section mt-3'>
          <h6>{t('error_server')}</h6>
        </Col>
      )}
      <Row>

    
      <Col xs={3}>
        <Col className='mb-3'>
          <p className='fw-bold'>{t('zipcode')}</p>
          <InputMask
            className={invalidZipCode ? 'form-invalid' : ''}
            mask='99999'
            onChange={(e) =>
              setData({
                zipCode: e.value,
                name,
                email,
                tel,
                counties,
                place,
                people,
                members,
                income,
                language
              })
            }
            onBlur={(e) => checkZIP()}
            placeholder='Código postal'
            value={zipCode}
            name='zipCode '
          ></InputMask>
          {invalidZipCode && (
            <p className='text-danger'>{t('zipcode_error')}</p>
          )}
        </Col>    
     
        
       
      </Col>

      <Col xs={3}>
      <p className='fw-bold'>{t('county')}</p>
          {counties.length <= 1 && <label>{place.name}</label>}
          {counties.length > 1 && (
            <>
              <Dropdown
                className={place === undefined ? 'form-invalid' : ''}
                value={place}
                options={counties}
                onChange={onCountyChange}
                optionLabel='name'
                filter={true}
                showClear='true'
                filterBy='name'
                placeholder={t('county_select')}
              />
              {place === undefined && (
                <p className='text-danger'>{t('county_required')}</p>
              )}
            </>
          )}
      </Col>

      <Col xs={3}>

     
      <p className='fw-bold'>{t('family_members')}</p>   
      
                        
      <InputNumber inputId="horizontal" value={people.length} onValueChange={(e) =>  setData({
                zipCode,
                name,
                email,
                tel,
                counties,
                place,
                people,
                members: e.value,
                income,
                language
              })}   />

      </Col>
     
      <Col xs={3}>
      <p className='fw-bold'>{t('household_income')}</p>
          <InputNumber
            className='w-50'
            inputId='currency-us'
            
            currency='USD'          
            value={income}
            onValueChange={(e) =>
              setData({
                zipCode,
                name,
                email,
                tel,
                counties,
                place,
                people,
                members,
                income: e.value,
                language
              })
            }
            locale='en-US'
          />
      </Col>
    
      </Row>

      <Row>
         <Col xs={4}>
         </Col>
      </Row> 
      <Col className='col-4'>
        <Col className='mb-3'>
          
        </Col>
        <Col className='mb-3'>
         
        </Col>
      </Col>
      <Col className='row mt-3'>
        <Col className='col-6 offset-4'>
         
        </Col>
      </Col>
    </Row>
    <Row>
    <Col >
          {/* <p className='fw-bold'>{t('applygin_coverage')}</p> */}
          
          {people.map((member, i) => {
            if (member.opened) {
              return (
                <Col  key={i}>
                  <Col className='row text-center'>
                    {/* <h5>{titleCard(member.applicant)}</h5> */}
                  </Col>
                  <Col className='row'>
                    <Col className='col-6'>
                      <p className='fw-bold'>
                      {
                        member.applicant==0 ? t('me_age') : t('age')
                      }
                      </p>
                      <InputNumber
                        onValueChange={(e) =>
                          onChangeFamilyInfo('real_age', i, e.value)
                        }
                        value={member.real_age}
                        // className={
                        //   invalidAge[i] === false ? 'form-invalid' : ''
                        // }
                        onBlur={(e) => checkAge(member, i)}
                        inputId='withoutgrouping'
                        placeholder={t('age')}
                        useGrouping={false}
                        
                      />
                        <div className='display d-none'>
                      {member.age = member.real_age}
                      </div> 
                      
                      <Col className='mt-3'>
                        <Col className='field-checkbox'>
                          <Checkbox
                            inputId='tabaco'
                            name='tabaco'
                            value='tabaco'
                            checked={member.uses_tobacco}
                            onChange={(e) => {
                              onChangeFamilyInfo(
                                'uses_tobacco',
                                i,
                                !member.uses_tobacco
                              );
                            }}
                          />
                          <label className='label-checks'>
                            {t('uses_tobacco')}
                          </label>
                        </Col>
                        {member.gender === 'F' && (
                          <Col className='field-checkbox'>
                            <Checkbox
                              inputId='pregnant'
                              name='pregnant'
                              value='pregnant'
                              checked={member.pregnant}
                              onChange={(e) => {
                                onChangeFamilyInfo(
                                  'pregnant',
                                  i,
                                  !member.pregnant
                                );
                              }}
                            />
                            <label
                              htmlFor='pregnant'
                              className='label-checks'
                            >
                              {t('pregnant')}
                            </label>
                          </Col>
                        )}
                      </Col>
                    </Col>
                    <Col className='col-3'>
                      <p className='fw-bold'>{t('gender')}</p>
                      <SelectButton
                        options={options}
                        value={member.gender === 'Male' ? 'M' : 'F'}
                        onChange={(e) => {
                          onChangeFamilyInfo(
                            'gender',
                            i,
                            e.value === 'M' ? 'Male' : 'Female'
                          );
                        }}
                      />
                     {member.applicant === 1  && (
                      <>                     
                        <Checkbox
                        checked={member.has_mec}
                        inputId='has_mec'
                        name='has_mec'
                        value={'has_mec'}

                        onChange={(e) => {
                          onChangeFamilyInfo(
                            'has_mec',
                                  i,
                                  !member.has_mec
                          )}}                                      
                        />
                        <label  htmlFor='has_mec'
                              className='label-checks'>
                          Marks as not eligible
                        </label>          
                   </>


                     )}
                    
                    </Col>

                  </Col>
                  <Col className='row mt-3'>
                    <Col className='col-6 text-center'>
                      {member.applicant > 0 && (
                        <button
                          type='button'
                          className='btn btn-outline-primary'
                          onClick={() => onDeleteMember(i)}
                        >
                          {t('delete')}
                        </button>
                      )}
                    </Col>
                    <Col className='col-6 text-center'>
                      <button
                        type='button'
                        className='btn btn-outline-primary'
                        onClick={(e) => {
                          onChangeFamilyInfo('opened', i, !member.opened);
                        }}
                      >
                        {t('close')}
                      </button>
                    </Col>
                  </Col>
                </Col>
              );
            }
            return (
              <Col className='card p-2 mt-3 mx-2'>
                <Col className='row'>
                  <Col
                    className='col'
                    role='button'
                    onClick={(e) => {
                      onDeleteMember(i);
                    }}
                  >
                    {member.applicant > 0 && <i className='pi pi-trash iconos'></i>}
                  <Col className='col-3 mx-3 my-auto d-flex align-items-center'>
                    <p className='m-0'>
                      {member.gender === 'Male' ? t('man') : t('woman')},{' '}
                      {member.age} {t('age')}
                    </p>
                  </Col>
                  {/* <Col className='dependientes col-2' >{

                   people.applicant===1 ?
                    t('dependent'):"no es dependiente"
                    }
                    
                    </Col> */}
                  <Col
                    className='col-2 offset-3'
                    role='button'
                    onClick={(e) => {
                      onChangeFamilyInfo('opened', i, !member.opened);
                    }}
                  >
                    <i className='pi pi-user-edit iconos'></i>
                  </Col>
                </Col>
              </Col>
            </Col>
            );
          })}
          
        </Col>

    </Row>

      
    <Row className='mt-5 d-flex ' >
    <Col  >
            {!found && (
              <Button
                type='button'
                className='btn btn-outline-primary w-80 text-center'
                onClick={() => [
                  setData({
                    zipCode,
                    name,
                    email,
                    tel,
                    counties,
                    place,
                    people: [...people, { ...MEMBER, applicant: 1, has_mec:false,}],
                    members,
                    income,
                    language
                  }),
                  checkAge(MEMBER, people.length),
                
                ]}
              >
                {t('spouse_applicant')}
              </Button>
            )}
           
          </Col>
          <Col >
          <Button
              type='button'
              className='btn btn-outline-primary w-80 text-center'
              onClick={() => [
                setData({
                  zipCode,
                  name,
                  email,
                  tel,
                  counties,
                  place,
                  people: [...people, MEMBER],
                  members,
                  income,
                  language
                }),
                checkAge(MEMBER, people.length),
              
              
              ]}
            >
              {t('dependent_applicant')}
            </Button>
          </Col>
    </Row>

    <Row className='mt-5 mb-5'>
    
    <Button
            disabled={
             invalidZipCode ||
              !place ||
              Object.keys(place).length === 0 
             }
            type='submit'
            label={t('see_plans')}
            className='p-button-info w-100 text-center p-button2'
            onClick={() => getEligibility()}
          />

          
         
    </Row>
     </Container>
   
  );
};
