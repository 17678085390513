import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as API from '../../services/quoter';
import { DataContext } from '../../context/DataContext';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap'
import '../../i18n';
import '../Quoter/styles/Quoter.css'

export const FormCheckZIP = () => {
  const { t } = useTranslation();
  const {
    data: { place, people, members, income, language },
    setData,
  } = useContext(DataContext);
  const [invalidZipCode, setInvalidZipCode] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setData({
      zipCode: '',
      name: '',
      email: '',
      tel: '',
      counties: [],
      place: {},
      people: [
        {
          applicant: 0,
          age: null,
          gender: 'Male',
          uses_tobacco: false,
          pregnant: false,
          opened: true,
          aptc_eligible: true,
          has_mec: false,          
        },
      ],
      members: 0,
      income: 0,
      language: 'en'
    });
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const checkZIP = ({ zipCode, name, email, tel }) => {
    API.getCountriesByZip(zipCode)
      .then(({ counties }) => {
        if (counties.length <= 0) {
          setInvalidZipCode(true);
        } else {
          setData({
            zipCode,
            name,
            email,
            tel,
            counties,
            place,
            people,
            members,
            income,
            language
          });
          navigate('/quoter');
        }
      })
      .catch((err) => {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 6000);
      });
  };

  const onSubmit = (data) => {
    checkZIP(data);
  };
  return (
    <Container fluid>
      <Row className='form-bg'> 
        <Col>
          <div className='form-container'>
            <h1 className='text-center mt-5'>{t('welcome')}</h1>
            <div className='form-zip'>
              {error && (
                <div className='error-section'>
                  <h6>{t('error_server')}.</h6>
                </div>
              )}
              {invalidZipCode && (
                <div className='error-section'>
                  <h6>{t('zipcode_error')}</h6>
                </div>
              )}
              <p>{t('enter_data')}</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <input
                    className={
                      errors.zipCode?.type === 'required' ||
                        errors.zipCode?.type === 'minLength' ||
                        errors.zipCode?.type === 'maxLength'
                        ? 'input-required'
                        : ''
                    }
                    placeholder={t('zipcode')}
                    type='tel'
                    name='zipCode'
                    {...register('zipCode', {
                      required: true,
                      minLength: 5,
                      maxLength: 5,
                    })}
                  />
                  {errors.zipCode?.type === 'required' && (
                    <span>{t('zipcode_required')}</span>
                  )}
                  {(errors.zipCode?.type === 'minLength' ||
                    errors.zipCode?.type === 'maxLength') && (
                      <span>{t('zipcode_validation')}</span>
                    )}
                </div>
                <div>
                  <input
                    placeholder={t('name')}
                    type='text'
                    name='name'
                    {...register('name', {
                      maxLength: 100,
                    })}
                  />
                </div>
                <div>
                  <input
                    className={
                      errors.email?.type === 'pattern' ? 'input-required' : ''
                    }
                    placeholder={t('email')}
                    type='text'
                    name='email'
                    {...register('email', {
                      pattern: /^\S+@\S+\.\S+$/,
                    })}
                  />
                  {errors.email?.type === 'pattern' && (
                    <span>{t('email_validation')}</span>
                  )}
                </div>
                <div>
                  <input
                    className={
                      errors.tel?.type === 'maxLength' ? 'input-required' : ''
                    }
                    placeholder={t('phone')}
                    type='text'
                    name='tel'
                    {...register('tel', {
                      maxLength: 10,
                    })}
                  />
                  {errors.tel?.type === 'maxLength' && (
                    <span>{t('phone_validation')}</span>
                  )}
                </div>
                <input className='planes' type='submit' value={t('plans')} />
              </form>
              <p className='mt-3 disclaimer'>{t('disclaimer')}</p>
            </div>
          </div>
        </Col>

      </Row>

    </Container>
  );
};
