import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../context/DataContext';
import { Eligibility } from './Eligibility';
import { Information } from './Information';
import * as API from '../../services/quoter';
import { Container, Row, Col } from 'react-bootstrap'
import './styles/Quoter.css';
import { Plans } from './Plans';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import logoF from '../../assets/images/YIT-blanco.png'



export const QuoterPage = () => {
  const { t } = useTranslation();
  const {
    data: { name, place, people, income, members },
  } = useContext(DataContext);
  const [errorPlan, setErrorPlan] = useState(false);
  const [error, setError] = useState(false);
  const [elegibilityData, setElegibilityData] = useState([]);
  const [plans, setPlans] = useState([]);
  const [filters, setFilters] = useState([]);
  const [memberstoSend, setMemberstoSend] = useState([]);
  const METALS = ['Bronze', 'Silver', 'Gold', 'Platinum'];
  const [pcfpl, setPcfpl] = useState('');
  const [csr, setCsr] = useState('')
  const [aptc, setAptc] = useState('')

  let dataPc;
  let dataAptc;

  const [state, setState] = useState({
    currentPage: 1,
    numberPages: 0,
  });

  const getPcflp = () => {
    let size = people.length
    let statefl = place.state
    let incomeh = income
    API.getPcflp(statefl, size, incomeh)
      .then((resp) => {
        if (resp) {
          let data = resp.pc_fpl
          setPcfpl(data)
          if (pcfpl >= 100 && pcfpl < 150) {
            dataPc = 'CSR94'
            setCsr(dataPc)
          }
          else {
            if (pcfpl >= 150 && pcfpl < 200) {
              dataPc = 'CSR87'
              setCsr(dataPc)
            }
            else {
              if (pcfpl >= 200 && pcfpl < 250) {
                dataPc = 'CSR73'
                setCsr(dataPc)
              }
              else {
                dataPc = 'CSR73'
                setCsr(dataPc)
              }
            }
          }
        }
      }
      )
    return dataPc;
  }
  const getPetitionEstimates = async (body) => {
    API.getEligibility(body)
      .then((resp) => {
        const estimates = resp.estimates;
        if (estimates.length > 0) {
          setElegibilityData(estimates);
          dataAptc = estimates[0].aptc
          setAptc(estimates[0].aptc)
          getPetitionPlans(estimates[0].aptc);
        }
      })
      .catch((err) => {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 6000);
      });
  }
  const getPetitionPlans = (aptc) => {
    const noChilds = people.filter((member) => member.age > 18);
    const filter = {
      metal_levels: METALS
    }
 
    getPlans(people, 10, 0, 'asc', 'premium', filter, true, aptc);
    
    setFilters([])
  }
  const getEligibility = async () => {

    const body = getJson(people);
    body.csr_override = await getPcflp()
    await getPetitionEstimates(body);
  };

  const getPlans = (
    members = [],
    limit = 10,
    offset = 0,
    order = 'asc',
    sort = undefined,
    filter = {},
    first = false,
    aptc_override = aptc,

  ) => {

    setMemberstoSend(members);
    setErrorPlan(false)
    const body = getJson(members);
    const hasMetals = filter.hasOwnProperty('metal_levels')
    if (!hasMetals || (hasMetals && filter.metal_levels && filter.metal_levels.length <= 0)) filter.metal_levels = METALS;
    body.limit = limit;
    body.offset = offset;
    body.order = order;
    body.sort = sort;
    body.filter = filter;
 //   body.csr_override = dataPc;
    body.aptc_override = aptc_override;

    API.getPlans(body)
      .then((resp) => {
        if (resp.plans) {
          setState((prevState) => ({ ...prevState, numberPages: 0 }));
          setPlans(resp.plans);
        //  console.log("datos body respuesta nuemero 3", body)
          if (first) setFilters(resp.facet_groups);
          const numberPages = Math.ceil(resp.total / 10);
          setState((prevState) => ({
            ...prevState,
            numberPages,
            currentPage: offset,
          }));

        }
        if (resp.status === '400') {
          if (resp.code === '1003') setErrorPlan(true);
          setState((prevState) => ({ ...prevState, numberPages: 0 }));
          setPlans([]);
          setFilters([]);
        }
      })
      .catch((err) => {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 6000);
      });
  };
  const getJson = (members) => {
    const marriedCouple = members.find((member) => member.applicant === 1);
    const county = {
      countyfips: place.fips,
      state: place.state,
      zipcode: place.zipcode,
    };
    const json = {
      household: {
        income,
        people: members,
        has_married_couple: marriedCouple ? true : false,
      },
      market: 'Individual',
      place: county,
      year: 2023,
      //aptc_override:aptc,
      // csr_override: csr
    };
    return json;
  };
  return (
    <Container className='bg-color-quoter' fluid >
      <Row className='header-info'>
        <h1 className='title_header'>
          {t('greeting')} {name}
        </h1>
        <h3 className='sub-title'>{t('data')} </h3>

      </Row>
      <Row className='flex-container ' >

        <Col className='contenedor-quoter'>
          <Information getEligibility={getEligibility} />
        </Col>


      </Row>
      <Row>

        <Col className='.contenedor-eligibility'>
          <Eligibility elegibilityData={elegibilityData} getPlans={getPlans} />
        </Col>

      </Row>
      <Row className='flex-containercard ' >
        <Col>
          {error && (
            <div className='error-section mt-3'>
              <h6>{t('error_server')}</h6>
            </div>
          )}
          <Plans
            errorPlan={errorPlan}
            plans={plans}
            filters={filters}
            getPlans={getPlans}
            members={memberstoSend}
            currentPage={state.currentPage}
            numberPages={state.numberPages}
          />
        </Col>
      </Row>
      <Row>
        <Row className='footer mt-1'>

          <Col><img src={logoF} className="logo-yti"></img></Col>
          <Col><p className='top-bar-text'><a href='mailto:contact@yestoinsurance.com'>contact@yestoinsurance.com</a></p></Col>
        </Row>
      </Row>



    </Container>

  );
};
