import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css'; //icons
import { Routes, Route } from 'react-router-dom';
import { FormCheckZIP } from './components/Home/FormCheckZIP';
import { QuoterPage } from './components/Quoter/QuoterPage';
import { Loading } from './components/Shared/Loading';
import { NavBar } from './components/Shared/NavBar';
import { DataProvider } from './context/DataContext';
import Footer from './components/Footer';

export function App() {
  return (
    <DataProvider>
      <Loading />
      <NavBar />
      <div className='App'>
        <Routes>
          <Route path='/' element={<FormCheckZIP />} />
          <Route path='quoter' element={<QuoterPage />} />
        </Routes>
      </div>
   
    </DataProvider>
  );
}
