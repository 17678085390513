import React, { useContext, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import '../../i18n'
import { Col, Container, Row } from 'react-bootstrap';
import '../Quoter/styles/Quoter.css';
import { InputText, InputTextarea } from 'primereact';
import * as API from '../../services/quoter';
import { useForm } from 'react-hook-form';
import { DataContext } from '../../context/DataContext';
import { Toast } from 'primereact/toast';

import oscar from '../../assets/images/carriers/oscar.png'
import cigna from '../../assets/images/carriers/cigna.png'
import blueCross from '../../assets/images/carriers/anthem.jpg'
import kaiser from '../../assets/images/carriers/kaiser.png'
import friday from '../../assets/images/carriers/friday.png'
import unitedhc from '../../assets/images/carriers/Uhc.png'
import aetna from '../../assets/images/carriers/aetna.jpg'
import ambetter from '../../assets/images/carriers/ambetter.png'
import careSource from '../../assets/images/carriers/caresource.png'
import floridaBlue from '../../assets/images/carriers/florida-blue.jpeg'
import molina from '../../assets/images/carriers/molinahc.png'
import avMed from '../../assets/images/carriers/avmed.png'
import community from '../../assets/images/carriers/communityhc.png'
import scottAndWhite from '../../assets/images/carriers/scottandwhite.png'
import imperialInsurance from '../../assets/images/carriers/ImperialInsurance.jpg'
import avera from '../../assets/images/carriers/avera-health-plans.png'
import sandford from '../../assets/images/carriers/sanford-health-plan.svg'
import sendero from '../../assets/images/carriers/sendero.png'
import modahc from '../../assets/images/carriers/modahealth.png'
import ascension from '../../assets/images/carriers/ascensionpc.png'
 
export const PlanCard = ({
  plan: {
    name,
    type,
    metal_level,
    premium,
    ehb_premium,
    premium_w_credit,
    deductibles,
    moops,
    benefits,
    benefits_url,
    issuer

  },
}) => {
  const toast = useRef(null);
  let emptyLead = {
    "name_lead": null,
    "last_name_lead": null,
    "mail_lead": null,
    "phone_lead": "",
    "notes": name
  };

  const { t } = useTranslation();
  const {
    data: { language },
  } = useContext(DataContext);
  const [lead, setLead] = useState(emptyLead);
  const [submitted, setSubmitted] = useState(false);
  const [leadDialog, setLeadDialog] = useState(false);
  const [mailDialog, setMailDialog] = useState(false);
  const [itemDeductibles, setItemDeductibles ] = useState()

 


  const {
    register,
    formState: { errors },
    handleSubmit, setValue, reset
  } = useForm();

  const openNew = () => {
    setLead(emptyLead);
    setSubmitted(false);
    setLeadDialog(true);
  }


  const sendClientEmail = () => {
    setSubmitted(false);
    setMailDialog(true);
  }




  const hideDialog = () => {
    setSubmitted(false);
    setLeadDialog(false);
  }

  const hideMailDialog = () => {
    setSubmitted(false);
    setMailDialog(false);
    setValue('email', '')
    reset({ email: '' })
  }


  const onInputChange = (e, name_lead) => {
    const val = (e.target && e.target.value) || '';
    let _lead = { ...lead };
    _lead[`${name_lead}`] = val;

    setLead(_lead);
  }


  const saveLead = async () => {

    const response = await fetch('https://api-v1.acacrm.com/leadsQuoter', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(lead),
    })
      .then((response) => response.json())

      .then((lead) => {
        console.log('Success:', lead);
      })

      .catch((error) => {
        console.error('Error:', error);
      });

    setSubmitted(true);
    setLeadDialog(false);


  }
  let amountValue
  const onSubmit = async (data) => {
    const json = {
      name: name,
      type: type,
      metal: metal_level,
      premium: premium,
      ehb: ehb_premium,
      premium_w_credit: premium_w_credit,
      deductibles: amountValue,
      emergency: displayString('Emergency Room Services'),
      drugs: displayString('Generic Drugs'),
      physician: displayString('Primary Care Visit to Treat an Injury or Illness'),
      medical: displayString('Specialist Visit'),
      moops: moops[0].amount,
      brochure: benefits_url,
      client_email: data.email,
      idiom: language,
      issuer:issuer.name
    }
    API.postSendMail(json)
      .then((resp) => {
        toast.current.show({severity:'success', summary: '', detail:`${t("send_mail")}`, life: 4000});
        hideMailDialog(true)
      })
      .catch((err) => {
        toast.current.show({severity:'error', summary: 'Ha ocurrido un error', detail:'No se pudo enviar el correo', life: 4000});
      });

  }

  const leadDialogFooter = (
    <>
      <Button label={t("close")} icon="pi pi-times" className="p-button-rounded p-button-info" onClick={hideDialog} />

      <Button label={t("contact_advisor")} icon="pi pi-check" className="p-button-rounded p-button-info" onClick={saveLead} />
      {/* <Button label="Editar" icon="pi pi-pencil" className="p-button-text" onClick={EditarLead }  /> */}
    </>
  );

  const mailDialogFooter = (
    <>
      <Button label={t("close")} icon="pi pi-times" className="p-button-rounded p-button-info" onClick={hideMailDialog} />

      {/* <Button label="Editar" icon="pi pi-pencil" className="p-button-text" onClick={EditarLead }  /> */}
    </>
  );


  const displayString = (name) => {
    const benefit = benefits.find((item) => item.name === name);
    if (benefit.covered) {
      return benefit.cost_sharings[0].display_string;
    }
    return '';
  };

  const carrier = 'oscar';
  return (

    <Container  >
      <Toast ref={toast} />
      <div className='card ' >
        <Row className='title-card under-line-title bg-header'>
          <Col >
          <div className='logos-carrier'>
          {
            issuer.name.includes('Aetna')? <img className='logos-carrier-img' src={aetna}  alt={issuer.name} /> :
            issuer.name.includes('Ambetter')? <img className='logos-carrier-img' src={ambetter}  alt={issuer.name} /> :
            issuer.name.includes('Avera')? <img className='logos-carrier-img' src={avera}  alt={issuer.name} /> :
            issuer.name.includes('AvMed')? <img className='logos-carrier-img' src={avMed}  alt={issuer.name} /> :
            issuer.name.includes('Blue Cross')? <img className='logos-carrier-img' src={blueCross}  alt={issuer.name} /> :
            issuer.name.includes('CareSource')? <img className='logos-carrier-img' src={careSource}  alt={issuer.name} /> :
            issuer.name.includes('Cigna')? <img className='logos-carrier-img' src={cigna}  alt={issuer.name} /> :
            issuer.name.includes('Community Health Choise')? <img className='logos-carrier-img' src={community}  alt={issuer.name} /> :
            issuer.name.includes('Friday')? <img className='logos-carrier-img' src={friday}  alt={issuer.name} /> :
            issuer.name.includes('Florida Blue')? <img className='logos-carrier-img' src={floridaBlue}  alt={issuer.name} /> :
            issuer.name.includes('IdealCare')? <img className='logos-carrier-img' src={sendero}  alt={issuer.name} /> :
            issuer.name.includes('Imperial Insurance Companies')? <img className='logos-carrier-img' src={imperialInsurance}  alt={issuer.name} /> :
            issuer.name.includes('Kaiser')? <img className='logos-carrier-img' src={kaiser}  alt={issuer.name} /> :
            issuer.name.includes('Moda Health')? <img className='logos-carrier-img' src={modahc}  alt={issuer.name} /> :
            issuer.name.includes('Molina')? <img className='logos-carrier-img' src={molina}  alt={issuer.name} /> :
            issuer.name.includes('Oscar')? <img className='logos-carrier-img' src={oscar}  alt={issuer.name} /> :
            issuer.name.includes('Sanford Health Plan')? <img className='logos-carrier-img' src={sandford}  alt={issuer.name} /> :
            issuer.name.includes('Scott and White')? <img className='logos-carrier-img' src={scottAndWhite}  alt={issuer.name} /> :
            issuer.name.includes('US Health and Life')? <img className='logos-carrier-img' src={ascension}  alt={issuer.name} /> :
            issuer.name.includes('UnitedHealthcare')? <img className='logos-carrier-img' src={unitedhc}  alt={issuer.name} /> :
           
           <p className='fw-bold'>{t('issuers')}: {issuer.name}</p>

           
          }
          </div>
         
         {/* <p className='fw-bold'>{t('issuers')}: {issuer.name}</p>*/}
            <p className='fw-bold size-t'>Plan: {name}</p>
          </Col>
          <Col>
          {/* */}  <p className='fw-bold size-t'>Metal:</p> <p><span className='size-t'> {metal_level} | {type}{' '}</span>  </p>
          </Col>
        </Row>
        <Row className='flex-containercard'>
          <Col className='cost-card'>
            <p className='under-line size-t fw-bold'>{t("premium")}</p>
            <p className='premium-cost '>${premium_w_credit.toFixed(2)}</p>
            <p className='text-danger text-decoration-line-through'>
              {t("was")}{premium.toFixed(2)}
            </p>
          </Col>
          <Col className='cost-card'>
            <p className='fw-bold under-line size-t'>{t("deductible")}
            
            </p>
            <p className='fs-4 deductible '>
            {/* <p className='fs-4 deductible '>${deductibles[0].amount}</p> */}
                { deductibles.map((item) => {
                  if((item.family_cost==='Individual' || item.family_cost==='Family Per Person') && item.type.includes('Medical') )                
                 amountValue = item.amount
              
                    return <span>{amountValue}</span>;
                    
                 }) }
            </p>
            <p className='size-t'>{t("individual_total")}</p>
          </Col>
          <Col className='cost-card'>
            <p className='fw-bold under-line size-t'>{t("moops")}</p>
            {/* <p className='fs-4 moops'>${moops[0].amount}</p> */}

            <p className='fs-4 moops size-t'>
            { moops.map((item) => {
                  if(item.family_cost==='Individual' || item.family_cost==='Family Per Person' )
                    return <span>{item.amount}</span>;
                 }) }
            </p>
            <p className='size-t'>{t("individual_total")}</p>
          </Col>
        </Row>
        <Row>
          <Col className='under-line-title title-card'>
            <p className='fw-bold size-t'>{t("copays_coinsunrance")}</p>
          </Col>
        </Row>

        <Row className='pre-footer '>
          <Col>
            <p className='size-t'>{t("emergency_care")}</p>
            <p className='size-t'> {displayString('Emergency Room Services')}</p>
          </Col>
          <Col>
            <p className='size-t'>{t("generic_drugs")}</p>
            <p className='size-t'> {displayString('Generic Drugs')}</p>
          </Col>
          <Col>
            <p className='size-t'>{t("primary_care_physician")}</p>
            <p className='size-t'>
              {displayString(
                'Primary Care Visit to Treat an Injury or Illness'
              )}
            </p>
          </Col>
          <Col>
            <p className='size-t'>{t("medical_specialist")}</p>
            <p className='size-t'> {displayString('Specialist Visit')}</p>
          </Col>
        </Row>
        <Row className='footer-card'>
        
          <Col xs={4}>
            <Button
              type='submit'
              label={t("summary_of_benefits")}
              className='p-button-info text-center'
              onClick={() => window.open(benefits_url, '_blank')}
            />
          </Col>
          <Col xs={4}>
            <Button
              type='submit'
              className='p-button-info3 text-center'
              label={t("email_client")}
              onClick={sendClientEmail}
            />
          </Col>
          <Col xs={4}>
            <Button
              type='submit'
              className='p-button-info2 text-center'
              label={t("contact_advisor")}
              onClick={openNew}
            />
          </Col>

        </Row>

      </div>


      <Dialog visible={leadDialog} style={{ width: '550px' }} header={t("contact_advisor")} modal className="p-fluid glass" footer={leadDialogFooter} onHide={hideDialog} >

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="name_lead">{t('name_contact_advisor')}</label>
            <InputText id="name_lead" value={lead.name_lead} onChange={(e) => onInputChange(e, 'name_lead')} autoFocus  required />

          </div>
          <div className="field col">
            <label htmlFor="last_name_lead">{t('last_name')}</label>
            <InputText id="last_name_lead" value={lead.last_name_lead} onChange={(e) => onInputChange(e, 'last_name_lead')} />
          </div>

        </div>
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="mail_lead">{t('email')}</label>
            <InputText id="mail_lead" value={lead.mail_lead} onChange={(e) => onInputChange(e, 'mail_lead')} className={classNames({ 'p-invalid': submitted && !lead.mail_lead })} />
            {submitted && !lead.mail_lead && <small className="p-error">{t('email_validation')}</small>}
          </div>
          <div className="field col">
            <label htmlFor="phone_lead">{t('phone_contact_advisor')}</label>
            <InputText id="phone_lead" value={lead.phone_lead} onChange={(e) => onInputChange(e, 'phone_lead')} required className={classNames({ 'p-invalid': submitted && !lead.phone_lead })} />
            {submitted && !lead.phone_lead && <small className="p-error">{t('phone_validation')}.</small>}
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="notes">PLAN</label>
            <InputTextarea id="notes" value={lead.notes} onChange={(e) => onInputChange(e, 'notes')} />

          </div>

        </div>


      </Dialog>

      <Dialog visible={mailDialog} style={{ width: '550px' }} header={t("email_client")} modal className="p-fluid glass" footer={mailDialogFooter} onHide={hideMailDialog} >
        <form onSubmit={handleSubmit(onSubmit)} >
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="client_email">{t('email')}</label>
              <InputText
                className={
                  (errors.email?.type === 'pattern' || errors.email?.type === 'required') ? 'form-invalid' : ''
                }
                placeholder={t('email')}
                type='text'
                name='email'
                {...register('email', {
                  required: true,
                  pattern: /^\S+@\S+\.\S+$/,
                })}
              />
              {(errors.email?.type === 'pattern' || errors.email?.type === 'required') && (
                <span className='moops'>{t('email_validation')}</span>
              )}
            </div>
          </div>
          <Button label={t("email_client")} type="submit" icon="pi pi-check" className="p-button-rounded p-button-info mt-3" />
        </form>

      </Dialog>


    </Container>


  );
};