import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../context/DataContext';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from "react-i18next";
import * as API from '../../services/quoter';
import '../../i18n'

import './styles/Quoter.css';
import { Col, Container, Row } from 'react-bootstrap';
import { getEligibility } from '../../services/quoter';
import { QuoterPage } from './QuoterPage';
import { json } from 'react-router-dom';
export const Eligibility = ({ elegibilityData, getPlans, }) => {

  const { t } = useTranslation();

  const [eligible, setEligible] = useState([]);
  const [peopleElegible, setPeopleElegible] = useState([]);
  const [notEligible, setNotEligible] = useState([]);
  const [eligibleChip, setEligibleChip] = useState([]);
  const [peopleChip, setPeopleChip] = useState([]);
  const [error, setError] = useState(false);
  const [aptc, setAptc] = useState('');
  const [agePut, setPutAge] = useState('')
  const [filters, setFilters] = useState([]);

  const {
    data: { place, people, income, },
  } = useContext(DataContext);
  useEffect(() => {
    setPeopleElegible([]);
    setEligible([]);
    setNotEligible([]);
    setEligibleChip([]);
    setPeopleChip([]);

    // const eligibleData = elegibilityData.filter(
    //   (member) =>
    //     !member.hardship_exemption &&
    //     !member.is_medicaid_chip &&
    //     member.in_coverage_gap,
    // );
    const notEligibleData = elegibilityData.filter(
      (member) =>
        member.hardship_exemption &&
        !member.is_medicaid_chip &&
        member.in_coverage_gap

    );
    const eligibleChipData = elegibilityData.filter(
      (member) => !member.is_medicaid_chip   //se agrego negacion
    );
    // const eligibleHasMec = elegibilityData.filter(
    //   (member) => !member.has_mec
    // )

    const peopleElegibleData = [];
    people.forEach((member, i) => {
      if (i <= eligibleChipData.length - 1 && !member.has_mec) {    //se agrego - 1
        peopleElegibleData.push(member);

      }
    });
    const peopleChipData = [];
    people.forEach((member, i) => {
      if (member.age < 19 || member.has_mec) {
        peopleChipData.push(member);
      }
    });
    setPeopleElegible(peopleElegibleData);
    setEligible(elegibilityData);
    setNotEligible(notEligibleData);
    setEligibleChip(eligibleChipData);
    setPeopleChip(peopleChipData);
  }, [elegibilityData]);

  const onCheckChip = (member, i) => {   
    const chips = peopleChip;
    chips.splice(i, 1);
    setPeopleChip(chips);
    if(member.applicant<=1){
      member.has_mec = false
    } else {
      if(member.real_age < 19){
        member.age =19
      }     
      member.has_mec = false
     }
    setPeopleElegible((prevData) => [...prevData, { ...member, }]);
   // getPlans(people);
    const body = getJson([...peopleElegible, { ...member }, ...chips]);
    API.getEligibility(body)
      .then((resp) => {
  
        if (resp.estimates.length > 0) {
          setEligible(resp.estimates);
          setAptc(eligible[0].aptc)       
          getPlans(people, 10, 0, 'asc', undefined, undefined, true, resp.estimates[0].aptc);
        }
      })
      .catch((err) => {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 6000);
      });

  };



  const onUnCheckChip = (member, i) => {
    const chips = peopleElegible;
    chips.splice(i, 1);
   // getPlans(people);

    setPeopleElegible(chips);
    people.forEach((member, i) => {
      member.age = member.real_age
    }
    );
    member.age = member.real_age
    member.has_mec = true
    const body = getJson([...chips, ...peopleChip, { ...member }]);
    setPeopleChip((prevData) => [...prevData, { ...member }]);
    API.getEligibility(body)
      .then((resp) => {
     
        if (resp.estimates.length  > 0) {
          setEligible(resp.estimates);
          setAptc(eligible[0].aptc);        
          getPlans(people, 10, 0, 'asc', undefined, undefined, true, resp.estimates[0].aptc);
          console.log("get plans", getPlans)
                 

        }
      })
      .catch((err) => {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 6000);
      });

  };

  const getJson = (members) => {
    const marriedCouple = members.find((member) => member.applicant === 1);
    const county = {
      countyfips: place.fips,
      state: place.state,
      zipcode: place.zipcode,
    };
    const json = {
      household: {
        income,
        people: members,
        has_married_couple: marriedCouple ? true : false,
      },
      market: 'Individual',
      place: county,
      year: 2023,
     // aptc_override: eligible[0].aptc,
      // csr_override: eligible[0].csr
    };
    return json;
  };


  return (

    <Container >
      <Row className="Eligibility-header">
             <h3 className='title_header_e'>{t("eligibility")}</h3>
      </Row>
      <Row>
        <Col>     
          {peopleChip.length > 0 && notEligible <= 0 && (
            <div className=''>
              <div className='row py-3'>
                <div className='col-12 d-flex'>
                  {/* <p className='my-auto'>
                    {t("not_eligibility")}
                  </p> */}
                </div>
              </div>
            </div>
          )}
          {notEligible.length > 0 && (
            <div className=''>
              <div className='row py-3'>
                <div className='col-12 d-flex'>
                  {/* <p className='my-auto'>
                    {t("not_elegible_applicant")}
                  </p> */}
                </div>
              </div>
            </div>
          )}
          {eligibleChip.length > 0 && notEligible >= 0 && (
            <div className='col-12'>
              {/* <p>{t("elegible")}</p> */}
              <ul>
                {peopleElegible.map((el, i) => {
                  return (
                    <li>
                      <p>
                        {el.gender}, {el.real_age}
                       
                          <div className='field-checkbox'>
                            <Checkbox
                              checked={true}
                              onChange={(e) => {
                                onUnCheckChip(el, i);
                              }}
                            />
                            <label className='label-checks'>
                            {
                          el.real_age < 19 ?   `${t("chip_denied")}` : `${t("chip_not_elegible")}`
                        }
                            </label>
                          </div>
                       
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </Col>
        <Col>
          {(notEligible.length > 0 || eligibleChip.length > 0) && (
            <div className='col-12 mt-3'>
              {/* <p>{t("not_elegible")}</p> */}
              <ul>
                {people.map((el, i) => {
                  if (notEligible.length > 0 && i < notEligible.length) {
                    return (
                      <li>
                        <p className='m-0'>
                          {el.gender}, {el.age}
                        </p>
                        <p className='chip'>
                       
                          {t("medicaid_elegible")}
                        </p>
                      </li>
                    );
                  }
                  return null;
                })}
                {peopleChip.map((el, i) => {
                  return (
                    <li>
                      <p className='m-0'>
                        {el.gender}, {el.age}
                      </p>
                  
                  { el.age <19 && (
                    <p className='chip'>                      
                        {t("chip_njfc")}
                      </p> 
                  )                       
                  }
                     
                      <div className='field-checkbox'>
                        <Checkbox
                          checked={false}
                          onChange={(e) => {
                            onCheckChip(el, i);
                          }}
                        />
                        <label className='label-checks'>
                        {
                          el.age < 19 ?   `${t("chip_denied")}` : `${t("chip_not_elegible")}`
                        }

                          
                        </label>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

        </Col>
        <div className='row container'>



          {eligible.length > 0 && (<>
            <div className='card tarjeta-savings'>
              <h3>{t("savings")}: </h3>
              <h3 className='.Eligibility-header'>
                <b> ${eligible[0].aptc}</b>

                <span> {t("by_month")}</span>
              </h3>
            </div>
            <div className='card-found mt-5'><h1 className='title_header_found'>{t('card_found')}</h1></div>
          </>
          )}

        </div>
        <div>

        </div>
      </Row>

    </Container>

  );
};
