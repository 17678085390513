import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"


import {translationSP} from './locales/es/translations'
import {translationENG} from './locales/en/translations'

i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
     es: {
       translation: translationSP
     },
     en: {
       translation: translationENG
     }
   }
 });
 i18n.changeLanguage("en");
 