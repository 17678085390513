import axios from 'axios';
import * as CONSTANTS from '../Constants/Constans';
const year = 2023;

export const showLoading = () => {
  let loading = document.getElementById('loading');
  if (loading) {
    loading.classList.remove('hide');
    loading.classList.add('show');
  }
};

export const hideLoading = () => {
  let loading = document.getElementById('loading');
  if (loading) {
    loading.classList.remove('show');
    loading.classList.add('hide');
  }
};
export async function getCountriesByZip(zipCode) {
  try {
    showLoading();
    const response = await fetch(
      `${CONSTANTS.URL_QUOTER}${CONSTANTS.URL_ZIP}${zipCode}?apikey=${CONSTANTS.API_KEY}`
    );
    const data = await response.json();
    hideLoading();
    return data;
  } catch (error) {
    hideLoading();
    console.error(error);
  }
}

export async function getEligibility(body) {
  try {
    showLoading();
    const response = await fetch(
      `${CONSTANTS.URL_QUOTER}${CONSTANTS.URL_ELIGIBILITY}?year=2023&apikey=${CONSTANTS.API_KEY}`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }
    );
    const data = await response.json();
    hideLoading();
    return data;
  } catch (error) {
    hideLoading();
    console.error(error);
  }
}

export async function getPlans(body) {
  try {
    showLoading();
    const response = await fetch(
      `${CONSTANTS.URL_QUOTER}${CONSTANTS.URL_PLANS}?apikey=${CONSTANTS.API_KEY}`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }
    );
    const data = await response.json();
    hideLoading();
    return data;
  } catch (error) {
    hideLoading();
    console.error(error);
  }
}

export async function postSendMail(body) {
  try {
    showLoading();
    const response = await fetch(
      `${CONSTANTS.URL_CRM}${CONSTANTS.URL_MAIL}`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }
    );
    const data = await response.json();
    hideLoading();
    return data;
  } catch (error) {
    hideLoading();
    console.error(error);
  }
}

export async function getPcflp(state,size,income){
  try {
    showLoading();
    const response = await fetch (
      
      `${CONSTANTS.URL_QUOTER}${CONSTANTS.URL_PCFPL}?apikey=${CONSTANTS.API_KEY}&year=${year}&state=${state}&size=${size}&income=${income}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },      
      }
    )
    const data = await response.json();
    hideLoading();
    return data;
  } catch (error) {
    hideLoading();
    console.error(error);
  }
}

// export const getPcflp = (state,size,income) =>{
//  axios
//  .get(`${CONSTANTS.URL_QUOTER}${CONSTANTS.URL_PCFPL}?apikey=${CONSTANTS.API_KEY}&year=${year}&state=${state}&size=${size}&income=${income}`)
// //  .then(data => console.log("dtos de pobreza",data.data))
//     .then(res => {
//       const pcfpl = res.data
//       console.log("VALOR DE POBREZA PCFLP ",pcfpl) 
//     })
//  .catch(error => console.log(error))

// }