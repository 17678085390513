export const translationENG = {
    error_server: 'It was not possible to connect to the server',
    language: "Language",
    welcome:"Find a plan that suits your needs very easily.",
    enter_data:"Submit your details to evaluate different plans.",
    zipcode:"Zip Code",
    zipcode_error:"Invalid postal code. Please enter one from the United States.",
    zipcode_requiered:"ZIP code is required",
    zipcode_validation:"It must have 5 digits.",
    name:"Name",
    last_name:"Last Name",
    email:"Email",
    email_validation:"The email format is incorrect.",
    phone:"Phone Number",
    phone_validation:"It must be 10 digits.",
    plans:"See Plans and Prices",
    disclaimer:"Disclaimer: By submitting your information, you agree that Yes to Insurance may contact you at the above e-mail address or phone number between 9 a.m. and 7 p.m. (CST). Providing this consent is not a condition of purchase. You may cancel this communication at any time. ",
    
      /* Segunda parte del cotizador */ 
   /**Componte Quoterpage */
   greeting:"Hello",
   data:"Please fill the following information so we can determinate the best options for you.",
   /**Componente eligibility */
   eligibility:" Who is applying?",
   not_eligibility:"Some of your applicants are not eligible for Marketplace plans.",
   elegible:"Elegible",
   not_elegible:"Not eligible:",
   not_elegible_applicant:"Your applicants may not be eligible for savings on Marketplace plans.",
   chip_denied:"CHIP denied or ending",
   chip_not_elegible:"Applying",
   savings:"Savings",
   by_month:"/Per month",
   medicaid_elegible:"You may be eligible for current state Medicaid.",
   chip_njfc:"You may be eligible for CHIP",
   /**Componente Informacion */
   county:"County",
   county_select:"Select county",
   applygin_coverage:"Who is applying for coverage?",
   county_required:"County is required",
   me:"Me",
   spouse:"Spouse",
   dependent:"Dependent",
   me_age:"Your Age",
   age:"Age",
   member_age:"Age",
   male:"Male",
   female:"Female",
   man: "Man",
   woman: "Woman",
   uses_tobacco:"Tobacco user",
   pregnant:"Pregnant",
   gender:"Gender",
   spouse_applicant:"Add Spouse",
   dependent_applicant:"Add Dependent",
   family_members:"Family members",
   many:"How many?",
   household_income:"Household income",
   delete:"Delete",
   close:"Hide",
   see_plans:"See plans and prices",
   card_found:"We've found the best plans for you !",
   /* Componente Plans */
   plans_information:"Plans: ",
   invalid_zipCode: "Although you can sign up for an affordable health coverage no matter where you live, some states have their own website to apply and sign up for coverage. This link you can check which web site is available in your state: ",
   url_cuidado_salud: "https://www.healthcare.gov/marketplace-in-your-state/",
   no_registers: "No registers",
   /**Plan card */
   premium:"Estimated monthly premium",
   was:"was $",
   deductible:"Deductible",
   individual_total:"Individual Total",
   moops:"Maximum out-of-pocket costs",
   oops:"Lowest out-of-pocket costs",
   copays_coinsunrance:"Copays / Coinsurance",
   emergency_care:"Emergency care",
   generic_drugs:"Generic drugs",
   primary_care_physician:"Primary care physician",
   medical_specialist:"Medical specialist",
   referral_required:"Referral required:",
   summary_of_benefits:"Summary of benefits",
   email_client:"Send to Email",
   contact_advisor:"Contact Advisor",
   name_contact_advisor:"Name (required)",
   phone_contact_advisor:"Phone number (required)",

   /**Filters plan */
   order_by:"Order by",
   lower_premium:"Lowest premium",
   highest_premium:"Highest premium",
   lowest_deductible:"Lowest deductible",
   highest_deductible:"Highest deductible",
   metal_levels:"Metal levels",
   networks:"Networks",
   issuers:"Carrier",
   send_mail:"YOUR E-MAIL HAS BEEN SENT",

   /**top bar*/

   hours:"Hours: Mon. – Fri. from 10 a.m. to 6 p.m. (CST)"



   /**footer */


   

};